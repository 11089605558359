.calculatorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 20px 20px;
    width: 100%;
    max-width: 95%;
    box-sizing: border-box;
}

.calculatorStack {
    background: rgba(255, 255, 255, 0.9);
    padding: 25px 25px;
    border-radius: 20px;
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .calculatorStack {
        padding: 25px 50px;
    }

    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .ms-ChoiceField {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 815px) {
    .calculatorStack {
        padding: 25px 150px;
    }
}