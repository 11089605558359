.linkText {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 24px;
    white-space: nowrap;
}

@media only screen and (min-width: 400px) {
    .linkText {
        font-size: 26px;
    }
}

@media only screen and (min-width: 500px) {
    .linkText {
        font-size: 28px;
    }
}

@media only screen and (min-width: 600px) {
    .linkText {
        font-size: 32px;
    }
}

@media only screen and (min-width: 700px) {
    .linkText {
        font-size: 34px;
    }
}

@media only screen and (min-width: 800px) {
    .linkText {
        font-size: 38px;
    }
}

@media only screen and (min-width: 900px) {
    .linkText {
        font-size: 44px;
    }
}

@media only screen and (min-width: 1000px) {
    .linkText {
        font-size: 48px;
    }
}